var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NotificationAlert',{ref:"alert"}),_c('p',{staticClass:"font-weight-bold text-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'my-4 d-none' : 'mt-10 mb-4'},[_vm._v(" "+_vm._s(_vm.botHeader)+" ")]),_c('div',{staticClass:"d-flex justify-center"},[(_vm.activeBot)?_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 50px'),attrs:{"text":""}},on),[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":_vm.activeBot.icon || _vm.botIcon,"max-width":_vm.$vuetify.breakpoint.smAndDown ? 30 : 50,"max-height":_vm.$vuetify.breakpoint.smAndDown ? 30 : 50}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.activeBot.name))]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)])]}}],null,false,2015140900)},[_c('v-list',[_vm._l((_vm.bots),function(item,index){return (item.id !== _vm.activeBot.id)?_c('v-list-item',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.changeBot(item)}}},[_c('div',{staticClass:"d-flex text-left my-2 align-center"},[_c('v-avatar',{class:item.id == _vm.activeBot.id ? 'active-bot' : 'default-bot',staticStyle:{"border-radius":"10px"},attrs:{"size":"30"}},[(item.icon)?_c('img',{attrs:{"src":_vm.getUserBotIcon(item),"alt":"Bot Image"}}):_c('v-icon',{attrs:{"size":"40","color":"grey","dark":""}},[_vm._v(" mdi-robot-outline ")])],1),_c('div',{staticClass:"text-left ml-2",class:item.id == _vm.activeBot.id ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(_vm.getUserBotName(item))+" ")])],1)]):_vm._e()}),_c('v-divider'),_c('v-list-item',{staticClass:"mt-2",on:{"click":function($event){$event.stopPropagation();return _vm.createBot.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex text-left my-2 align-center"},[_c('v-avatar',{staticStyle:{"border-radius":"5px","border":"2px dashed #000000de"},attrs:{"size":"30"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"text-left ml-2"},[_vm._v("Create Bot")])],1)])],2)],1),_c('div',{staticClass:"ml-2 px-2 font-weight-bold",class:_vm.activeBot.role_data === 'Owner'
            ? 'blue darken-1 text-white'
            : _vm.activeBot.role_data === 'Editor'
            ? 'green darken-1 text-white'
            : 'yellow darken-2 text-black',staticStyle:{"border-radius":"5px"}},[_vm._v(" "+_vm._s(_vm.activeBot.role_data)+" ")]),(
          !_vm.isEmpty(_vm.botForm) &&
          (_vm.getCurrentRoute == 'DashboardManageBots' ||
            _vm.getCurrentRoute == 'DashboardTrainBots')
        )?_c('v-divider',{staticClass:"px-1",staticStyle:{"height":"10px !important"},attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"ml-2"},[(
            !_vm.isEmpty(_vm.botForm) &&
            (_vm.getCurrentRoute == 'DashboardManageBots' ||
              _vm.getCurrentRoute == 'DashboardTrainBots')
          )?_c('v-switch',{attrs:{"dense":"","label":("Bot " + _vm.botStatus),"color":_vm.botForm && _vm.botForm.toggle ? 'success' : 'error',"loading":_vm.toggleLoading},on:{"change":_vm.handleBotToggle},model:{value:(_vm.botForm.toggle),callback:function ($$v) {_vm.$set(_vm.botForm, "toggle", $$v)},expression:"botForm.toggle"}}):_vm._e()],1)],1):_c('LoadingComponent',{staticClass:"align-center",attrs:{"overlayLoadingCenter":"","overlay":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }