<template>
  <div>
    <NotificationAlert ref="alert" />
    <p
      class="font-weight-bold text-center"
      :class="$vuetify.breakpoint.smAndDown ? 'my-4 d-none' : 'mt-10 mb-4'"
    >
      {{ botHeader }}
    </p>
    <div class="d-flex justify-center">
      <div v-if="activeBot" class="d-flex align-center">
        <!-- Button with Image and Text -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :style="
                $vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 50px'
              "
              text
            >
              <div class="d-flex align-center">
                <v-img
                  :src="activeBot.icon || botIcon"
                  :max-width="$vuetify.breakpoint.smAndDown ? 30 : 50"
                  :max-height="$vuetify.breakpoint.smAndDown ? 30 : 50"
                ></v-img>
                <span class="ml-1">{{ activeBot.name }}</span>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in bots"
              :key="index"
              @click.stop="changeBot(item)"
              v-if="item.id !== activeBot.id"
            >
              <div class="d-flex text-left my-2 align-center">
                <v-avatar
                  size="30"
                  style="border-radius: 10px"
                  :class="
                    item.id == activeBot.id ? 'active-bot' : 'default-bot'
                  "
                >
                  <img
                    :src="getUserBotIcon(item)"
                    alt="Bot Image"
                    class=""
                    v-if="item.icon"
                  />

                  <v-icon size="40" color="grey" dark v-else class="">
                    mdi-robot-outline
                  </v-icon>
                </v-avatar>

                <div
                  class="text-left ml-2"
                  :class="item.id == activeBot.id ? 'font-weight-bold' : ''"
                >
                  {{ getUserBotName(item) }}
                </div>
              </div>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click.stop="createBot" class="mt-2">
              <div class="d-flex text-left my-2 align-center">
                <v-avatar
                  size="30"
                  style="border-radius: 5px; border: 2px dashed #000000de"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-avatar>

                <div class="text-left ml-2">Create Bot</div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          class="ml-2 px-2 font-weight-bold"
          style="border-radius: 5px"
          :class="
            activeBot.role_data === 'Owner'
              ? 'blue darken-1 text-white'
              : activeBot.role_data === 'Editor'
              ? 'green darken-1 text-white'
              : 'yellow darken-2 text-black'
          "
        >
          {{ activeBot.role_data }}
        </div>

        <v-divider
          v-if="
            !isEmpty(botForm) &&
            (getCurrentRoute == 'DashboardManageBots' ||
              getCurrentRoute == 'DashboardTrainBots')
          "
          class="px-1"
          style="height: 10px !important"
          vertical
        ></v-divider>

        <div class="ml-2">
          <v-switch
            v-if="
              !isEmpty(botForm) &&
              (getCurrentRoute == 'DashboardManageBots' ||
                getCurrentRoute == 'DashboardTrainBots')
            "
            v-model="botForm.toggle"
            dense
            :label="`Bot ${botStatus}`"
            :color="botForm && botForm.toggle ? 'success' : 'error'"
            @change="handleBotToggle"
            :loading="toggleLoading"
          ></v-switch>
        </div>
      </div>

      <LoadingComponent
        v-else
        class="align-center"
        overlayLoadingCenter
        overlay
      />
    </div>
  </div>
</template>

<script>
import robotIcon from "@/assets/bots/robot-happy.svg";
import LoadingComponent from "@/components/helpers/Loading";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Utils from "@/helpers/utils";
import Pusher from "pusher-js";
import NotificationAlert from "@/components/helpers/NotificationAlert";
import { isEmpty } from "lodash";

export default {
  props: {
    botHeader: String,
    botForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    toggleLoading: Boolean,
  },
  components: {
    LoadingComponent,
    NotificationAlert,
  },
  data() {
    return {
      isLoading: false,
      hoveredItem: false,
      botIconDefault: null,

      showOptions: false,

      snackbarOption: false,
    };
  },
  methods: {
    ...mapMutations("bots", ["setActiveBot", "setContact"]),
    ...mapMutations("chat", ["setUserHistory"]),
    ...mapMutations("modal", ["setShowCreateBot"]),

    getUserBotName(item) {
      return item?.name;
    },

    getUserBotIcon(item) {
      if (item.icon != "") {
        return item.icon;
      }
    },

    createBot() {
      if (this.bots.length >= 3) {
        globalErrorHandler(
          null,
          "Chatbot limit reached, Please upgrade subscription."
        );
      } else {
        this.setShowCreateBot(true);
      }
    },

    changeBot(item) {
      //activate only if in playground
      if (
        this.bots.length > 1 &&
        this.getCurrentRoute == "DashboardPlayground"
      ) {
        Utils.showMessageDefault({
          position: "right",
          cancelButtonColor: "#ffffffff",
          confirmButtonColor: "#4285f3",
          confirmButtonText: "OKAY",
          denyButtonText: "Cancel",
          customClass: "playground-popup playground-page-popup",
          title: null,
          icon: null,
          text: "Switching to a different bot model will clear the current playground.",
          showDenyButton: false,
        }).then(
          (res) => {
            if (res.isConfirmed) {
              this.setActiveBot(item);
              this.setContact({
                emails: item?.emails,
                mobile_numbers: item?.mobile_numbers,
              });
              // this.$route.params.playgroundId = item.id;
              this.$router.push("/dashboard/playground/" + item.id);
            }
            return true;
          },
          (error) => {
            return false;
          }
        );
      } else if (this.getCurrentRoute == "DashboardManageBots") {
        this.setActiveBot(item);
        this.setContact({
          emails: item?.emails,
          mobile_numbers: item?.mobile_numbers,
        });
        this.$router.push("/dashboard/manage-bots/" + item.id);
      } else if (this.getCurrentRoute == "DashboardSettings") {
        this.setActiveBot(item);
        this.setContact({
          emails: item?.emails,
          mobile_numbers: item?.mobile_numbers,
        });
        this.$router.push("/dashboard/settings/" + item.id);
      } else if (this.getCurrentRoute == "DashboardHistories") {
        this.setActiveBot(item);
        this.setContact({
          emails: item?.emails,
          mobile_numbers: item?.mobile_numbers,
        });
        this.$router.push("/dashboard/history/" + item.id);
      } else if (this.getCurrentRoute == "DashboardTrainBots") {
        this.setActiveBot(item);
        this.setContact({
          emails: item?.emails,
          mobile_numbers: item?.mobile_numbers,
        });
        this.$router.push("/dashboard/train-bots/" + item.id);
      }
    },

    handleBotToggle() {
      this.$emit("toggle-changed");
    },
  },
  computed: {
    ...mapGetters("bots", ["bots", "activeBot"]),
    ...mapGetters("auth", ["currentUser"]),

    botIcon() {
      return robotIcon;
    },

    botStatus() {
      return this.activeBot.toggle ? "Activated" : "Deactivated";
    },

    getCurrentRoute() {
      return this.$route.name;
    },
    paramBotId() {
      return this.$route.params.botId;
    },

    hoveredResult() {
      if (this.hoveredItem) {
        return "border: 1px solid #000";
      } else {
        return "border: none !important";
      }
    },

    isEmpty() {
      return _.isEmpty;
    },
  },

  watch: {
    //setting bot in every page visit that have used this component
    getCurrentRoute: {
      immediate: false,
      handler(v) {
        this.isLoading = true;
        this.$emit("isLoadingBot", this.isLoading);

        setTimeout(() => {
          this.isLoading = false;
          this.$emit("isLoadingBot", this.isLoading);
        }, 500);
      },
    },
  },
};
</script>

<style>
/* Custom CSS to align avatars and names */
.v-avatar img {
  object-fit: cover;
}

.default-bot {
  border: 0.5px solid #27272a75;
}
.active-bot {
  border: 3px solid #4285f3;
}
</style>
